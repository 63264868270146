import { ImageUseageType } from "@/enums/Enums";
import { IAlterItemRequest } from "@/interfaces/Requests/Requests";
import { useAlterItemApi, useGetItemByMerchantApi, useGetItemSpecsApi } from "@/lib/api/items";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Divider, Flex, Modal, Steps, Tooltip, UploadFile } from "antd";
import { load } from "cheerio";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import AlterDescription from "../AddItemPages/Components/AlterDescription";
import BasicInformation, { IBasicInformationRef } from "../AddItemPages/Components/BasicInformation";
import PreviewItem from "../AddItemPages/Components/PreviewItem";
import SpecsForm from "../AddItemPages/Components/SpecsForm";

export interface EditItemPageProps { }

interface IMutationRequest {
    merchantId: BigInt,
    itemId: BigInt
}

export enum EditItemStep {
    FillInfo = 0,
    Preview = 1
}

const EditItemPage: React.FC<EditItemPageProps> = () => {
    const { merchantId, setIsPageLoading, messageApi } = useContext(GlobalContext);
    const { itemViewModel, setItemViewModel, itemSpecs, setItemSpecs, itemConverImageFiles, setItemConverImageFiles } = useContext(ItemContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const basicInfoRef = useRef<IBasicInformationRef>();
    const queryParams = new URLSearchParams(location.search);
    const itemId = BigInt(queryParams.get('itemId') || '0');
    const previewOnly = queryParams.get('previewOnly') === 'true';
    const navigate = useNavigate();
    const { mutate: itemVMMutate, isLoading: itemVMLoading } = useMutation(async (request: IMutationRequest) => await useGetItemByMerchantApi(request.itemId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                const data = response.result;
                setItemViewModel(data);
                setItemConverImageFiles(data.itemConverImagePaths.map((url) => ({ uid: uuidv4(), name: url, status: 'done', url: url } as UploadFile)));
            }
            else {
                navigate('/not-found');
            }
        }
    });
    const { mutate: itemSpecsMutate, isLoading: itemSpecsLoading } = useMutation(async (request: IMutationRequest) => await useGetItemSpecsApi(request.merchantId, request.itemId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                const data = response.result;
                setItemSpecs(data);
            }
            else {
                setItemSpecs([]);
            }
        }
    });
    const { mutateAsync } = useMutation(
        async () => {
            if (!itemViewModel || !merchantId) {
                return;
            }
            var alterRequest: IAlterItemRequest = {
                itemViewModel: itemViewModel,
                images: itemConverImageFiles.map((file) => file.originFileObj as File),
                needRemoveConverImages: itemViewModel.itemConverImagePaths.filter((path) => !itemConverImageFiles.some((file) => file.name === path)),
                itemId: itemViewModel.id,
            };
            alterRequest.itemViewModel.description = await getParsedDeiscriptionAsync(alterRequest, itemViewModel.description);
            var finalRequest = CombineitemConverImageFiles(alterRequest);
            var formData = new FormData();
            formData.append('ItemId', alterRequest.itemId.toString());
            formData.append('Title', alterRequest.itemViewModel.title);
            formData.append('Briefly', alterRequest.itemViewModel.briefly);
            formData.append('Description', alterRequest.itemViewModel.description);
            if (itemSpecs) {
                itemSpecs.forEach((itemSpec: any, index) => {
                    Object.keys(itemSpec).forEach((key) => {
                        formData.append(`ItemSpecs[${index}].${key}`, itemSpec[key] as string);
                    });
                });
            }
            itemViewModel.itemTags.forEach((tag) => {
                formData.append('FinalTagNames', tag.name);
            });
            finalRequest.images.forEach((file) => {
                formData.append('Images', file);
            });
            finalRequest.needRemoveConverImages.forEach((path) => {
                formData.append('NeedRemoveConverImages', path);
            });
            await useAlterItemApi(formData);
        },
        {
            onSuccess: (response) => {
                messageApi.success(translate('Operation success'));
                navigate('/itemManage');
            },
        }
    );
    const [currentStep, setCurrentStep] = React.useState<EditItemStep>(EditItemStep.FillInfo);
    const [isBasicInfoValid, setIsBasicInfoValid] = useState<boolean>(false);
    const [isSpecsAllValid, setIsSpecsAllValid] = useState<boolean>(false);

    useEffect(() => {
        if (itemId === BigInt(0)) {
            navigate('/not-found');
        }
        if (merchantId) {
            itemVMMutate({ merchantId: merchantId, itemId: itemId });
            itemSpecsMutate({ merchantId: merchantId, itemId: itemId });
        }
    }, [merchantId]);

    useEffect(() => {
        setIsPageLoading(itemVMLoading || itemSpecsLoading);
    }, [itemVMLoading, itemSpecsLoading]);

    useEffect(() => {
        if (basicInfoRef.current) {
            basicInfoRef.current?.validate();
        }
    }, [basicInfoRef.current]);

    useEffect(() => { }, [i18nLanguage]);

    function backToItemManage() {
        navigate('/itemManage');
    }

    async function editItemAsync() {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate('Are you sure to edit this item') + '?',
            okText: translate('Yes'),
            async onOk() {
                await mutateAsync();
            },
            cancelText: translate('No'),
        });
    }

    async function getParsedDeiscriptionAsync(request: IAlterItemRequest, description: string): Promise<string> {
        var content = description;
        if (!content) {
            return '';
        }
        const $ = load(content);

        const promises = $('img').map(async (index, element) => {
            const src = element.attribs.src;
            if (src.startsWith('data:')) {
                const res = await fetch(src);
                const blob = await res.blob();
                const fileName = `${ImageUseageType.ItemDescription}_${uuidv4()}.jpg`;
                const file = new File([blob], fileName, { type: 'image/jpeg' });
                content = content!.replace(src, fileName);
                request.images.push(file);
            }
        }).get();
        await Promise.all(promises);
        return content;
    }

    function CombineitemConverImageFiles(request: IAlterItemRequest): IAlterItemRequest {
        itemConverImageFiles.forEach((file) => {
            if (file.status === 'done') {
                return;
            }
            if (file.originFileObj) {
                var extension = file.name.split('.').pop();
                var fileName = `${ImageUseageType.ItemCover}_${uuidv4()}.${extension}`;
                const newFile = new File([file.originFileObj], fileName, { type: file.type });
                request.images.push(newFile);
            }
        });
        return request;
    }

    const handleNextStep = async () => {
        if (currentStep === EditItemStep.FillInfo) {
            const isBasicInfoValid = await basicInfoRef.current?.validate();
            const isSpecsAllValid = true; // Perform validation for specs as needed

            if (isBasicInfoValid && isSpecsAllValid) {
                setCurrentStep(EditItemStep.Preview);
            } else {
                messageApi.error(translate('Please complete all required fields.'));
            }
        } else {
            setCurrentStep(EditItemStep.Preview);
        }
    };

    const previewDisabledTooltip = i18nLanguage === 'zh_TW' ? '部分資料填寫未齊全' : 'Some required fields are incomplete';

    return (
        itemViewModel && itemSpecs &&
        <div>
            <div>
                <Steps style={{ padding: '30px' }} current={currentStep}>
                    <Steps.Step title={previewOnly ? translate('Detail') : translate('Edit item')} />
                    <Steps.Step title={translate('Preview')} />
                </Steps>
                {currentStep === EditItemStep.FillInfo && (
                    <>
                        <div>
                            <Flex style={{ margin: '30px' }} justify="center">
                                <BasicInformation
                                    ref={basicInfoRef}
                                    previewOnly={previewOnly}
                                    onIsAllValid={(isAllValid) => setIsBasicInfoValid(isAllValid)}
                                />
                            </Flex>
                        </div>
                        <Divider style={{ padding: '10px' }}></Divider>
                        <SpecsForm
                            previewOnly={previewOnly}
                            onIsAllValid={(isAllValid) => setIsSpecsAllValid(isAllValid)}
                        />
                        <Divider style={{ padding: '10px' }}></Divider>
                        <AlterDescription previewOnly={previewOnly} />
                        <Flex justify="center" style={{ margin: '30px' }}>
                            <Tooltip title={isBasicInfoValid && isSpecsAllValid ? '' : previewDisabledTooltip}>
                                <Button
                                    shape="round"
                                    type="primary"
                                    onClick={handleNextStep}
                                    disabled={!isBasicInfoValid || !isSpecsAllValid}
                                >
                                    {translate('Preview')}
                                </Button>
                            </Tooltip>
                        </Flex>
                    </>
                )}
                {currentStep === EditItemStep.Preview && (
                    <div>
                        <PreviewItem />
                        <Flex style={{ margin: '30px' }} justify="space-between">
                            <Button
                                shape="round"
                                type="primary"
                                onClick={() => setCurrentStep(EditItemStep.FillInfo)}
                            >
                                {translate('Previous Step')}
                            </Button>
                            <Button
                                shape="round"
                                hidden={previewOnly}
                                type="primary"
                                onClick={editItemAsync}
                            >
                                {translate('Submit')}
                            </Button>
                        </Flex>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditItemPage;
