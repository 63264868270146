import { IInviteAssistanceRequest, IRemoveAssistanceRequest } from "@/interfaces/Requests/Merchant";
import { IMerchantAssistant, } from "@/interfaces/Responses/Responses";
import { useGetAssistancesApi, useInviteAssistanceApi, useRemoveAssistanceApi } from "@/lib/api/merchants";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Modal, Space, Table } from "antd";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import PermissionModal from "./PermissionModal";

const { confirm } = Modal;

const useAssistants = () => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    const { data, isLoading, refetch } = useQuery<IOperationResultT<IMerchantAssistant[]>, Error>(
        'assistances',
        () => useGetAssistancesApi(undefined),
        {
            onError: () => {
                messageApi.error(translate('Failed to fetch assistants'));
            }
        }
    );

    return {
        assistants: data?.result || [],
        isLoading,
        refresh: refetch,
    };
};

const MerchantAssistantManage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const { assistants, isLoading, refresh } = useAssistants();
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    const [isPermissionModalVisible, setIsPermissionModalVisible] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState<IMerchantAssistant | null>(null);
    const [form] = Form.useForm();

    const { mutate: inviteAssistant, isLoading: isInviting } = useMutation<IOperationResult, Error, IInviteAssistanceRequest>(
        useInviteAssistanceApi,
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    setIsInviteModalVisible(false);
                    form.resetFields();
                    refresh(); // 成功後重新獲取數據
                } else {
                    messageApi.error(translate(response.message || 'Failed to invite assistant'));
                }
            },
            onError: () => {
                messageApi.error(translate('An error occurred while inviting assistant'));
            }
        }
    );

    const { mutate: removeAssistant, isLoading: isRemoving } = useMutation<IOperationResult, Error, IRemoveAssistanceRequest>(
        useRemoveAssistanceApi,
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    refresh(); // 成功後重新獲取數據
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const handleInvite = (values: { userId: string }) => {
        inviteAssistant({ userId: values.userId });
    };

    const showRemoveConfirm = (assistant: IMerchantAssistant) => {
        confirm({
            title: translate('Confirm'),
            icon: <ExclamationCircleOutlined />,
            content: translate('This action cannot be undone'),
            okText: translate('Yes'),
            okType: 'danger',
            cancelText: translate('No'),
            onOk() {
                removeAssistant({ userId: assistant.userId });
            },
        });
    };

    const columns = [
        {
            title: translate('User ID'),
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: translate('Status'),
            dataIndex: 'isAccepted',
            key: 'isAccepted',
            render: (isAccepted: boolean) => isAccepted ? translate('Accepted') : translate('Pending'),
        },
        {
            title: translate('CreatedTime'),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (date: Date) => new Date(date).toLocaleString(),
        },
        {
            title: translate('Operation'),
            key: 'actions',
            render: (_: any, record: IMerchantAssistant) => (
                <Space>
                    {record.isAccepted && (
                        <Button onClick={() => {
                            setSelectedAssistant(record);
                            setIsPermissionModalVisible(true);
                        }}>
                            {translate('Permissions')}
                        </Button>
                    )}
                    <Button
                        danger
                        onClick={() => showRemoveConfirm(record)}
                        loading={isRemoving}
                    >
                        {translate('Delete')}
                    </Button>
                </Space>
            ),
        },
    ];

    if (isLoading) return <LoadingComponent />;

    return (
        <>
            <Table
                columns={columns}
                dataSource={assistants}
                rowKey={(record) => record.id.toString()}
                pagination={{ position: ['bottomCenter'] }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={() => setIsInviteModalVisible(true)}
                        block
                        icon={<PlusOutlined />}
                    >
                        {translate('Invite new assistant')}
                    </Button>
                )}
                bordered
            />

            <Modal
                title={`${translate('Invite')} ${translate('Assistant')}`}
                open={isInviteModalVisible}
                onCancel={() => {
                    setIsInviteModalVisible(false);
                    form.resetFields();
                }}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={handleInvite}
                    layout="vertical"
                >
                    <Form.Item
                        name="userId"
                        label={translate('User ID')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Input placeholder={translate('Enter user ID')} />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isInviting}
                                    disabled={
                                        !form.isFieldsTouched(['userId']) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                                        !form.getFieldValue('userId')
                                    }
                                >
                                    {translate('Invite')}
                                </Button>
                            </Flex>
                        )}
                    </Form.Item>
                </Form>
            </Modal>

            <PermissionModal
                isVisible={isPermissionModalVisible}
                onCancel={() => setIsPermissionModalVisible(false)}
                selectedAssistant={selectedAssistant}
            />
        </>
    );
};

export default MerchantAssistantManage;
