import { IAddMerchantThirdPartyPaymentSettingRequest, IAlterMerchantThirdPartyPaymentSettingRequest } from "@/interfaces/Requests/Requests";
import { ICancelSubscribeRequest } from "@/interfaces/Requests/ThirdPartyPayment";
import { IBackOfficeMerchantThirdPartyPaymentSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyPaymentSetting } from "@/interfaces/Responses/ThirdPartyPayment";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from "../customHooks/useAPI";

export const useGetThirdPartyPaymentSettingApi = (settingId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IThirdPartyPaymentSetting>>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/GetThirdPartyPaymentSetting?settingId=${settingId.toString()}`, {
    signal: signal
});

export const useGetThirdPartyPaymentSettingsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IThirdPartyPaymentSetting[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/GetThirdPartyPaymentSettings`, {
    signal: signal
});

export const useGetBackOfficeMerchantThirdPartyPaymentSettingViewModelsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IBackOfficeMerchantThirdPartyPaymentSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/GetBackOfficeMerchantThirdPartyPaymentSettingViewModels`, {
    signal: signal
});

export const useAddMerchantThirdPartyPaymentSettingApi = (request: IAddMerchantThirdPartyPaymentSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/AddMerchantThirdPartyPaymentSetting`, {
    method: 'POST',
    body: request,
});

export const useAlterMerchantThirdPartyPaymentSettingApi = (request: IAlterMerchantThirdPartyPaymentSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/AlterMerchantThirdPartyPaymentSetting`, {
    method: 'POST',
    body: request,
});

export const useCancelSubscribeApi = (request: ICancelSubscribeRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/CancelSubscribe`, {
    method: 'POST',
    body: request,
});