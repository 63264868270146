import { ImageUseageType } from '@/enums/Enums';
import { IAlterPortalStyleRequest } from '@/interfaces/Requests/Merchant';
import { useUploadImageApi } from '@/lib/api/images';
import { useAlterPortalStyleApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { ConfigFieldType, DeviceType, MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { IConfigField } from '@/Templates/interfaces/templatesInterfaces';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, Modal, Row, Select, Tooltip, Upload } from 'antd';
import { Flex } from 'antd/lib';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';

// 定義 StyleConfigSelectorProps 介面
interface StyleConfigSelectorProps {
    type: MerchantPortalStyleSettingType;
    name: string;
    selectedStyle: number;
    styleConfig: Record<string, string>;
    configFields: Array<IConfigField>;
    onStyleChange: (value: number) => void;
    onConfigChange: (key: string, value: string) => void;
    styleOptions: Array<{ value: number; label: string }>;
}

// StyleConfigSelector 組件
const StyleConfigSelector: React.FC<StyleConfigSelectorProps> = ({
    type,
    name,
    selectedStyle,
    styleConfig,
    configFields,
    onStyleChange,
    onConfigChange,
    styleOptions,
}) => {
    // 使用 Context
    const { deviceType, messageApi, merchantId, getMerchantPortalStyleSettings } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    // 狀態管理
    const [drawerVisible, setDrawerVisible] = useState(false);
    var forMutipleUploadFileList: any[] = [];

    // 修改門戶樣式的 API 調用
    const alterPortalStyleApi = useMutation(async (request: IAlterPortalStyleRequest) => useAlterPortalStyleApi(request), {
        onSuccess: () => {
            getMerchantPortalStyleSettings.mutate({
                merchantId: merchantId!,
            });
            setDrawerVisible(false);
            messageApi.success(translate('Operation successful'));
        },
        onError: () => {
            messageApi.error(translate('Operation failed'));
        }
    });

    // 上傳圖片的 API 調用
    const uploadImagesMutation = useMutation<string[], Error, { file: File; fieldKey: string }[]>(
        async (data) => {
            const uploadPromises = data.map(async (item) => {
                const formData = new FormData();
                formData.append('File', item.file);
                formData.append('UseageType', ImageUseageType.ForPreview);
                formData.append('ReferenceId', merchantId ? merchantId.toString() : '');
                const response = await useUploadImageApi(formData);
                if (response.isSuccess && response.result) {
                    return response.result;
                }
                throw new Error('Image upload failed');
            });

            const results = await Promise.all(uploadPromises);
            return results;
        },
        {
            onSuccess: (result, variables) => {
                const fieldKey = variables[0].fieldKey;
                const newFileList = result.map((url: string) => ({
                    uid: url,
                    name: url,
                    status: 'done',
                    url: url,
                }));
                onConfigChange(fieldKey, JSON.stringify(result));
            },
            onError: (error) => {
                messageApi.error(translate('Image upload failed'));
                console.error('Upload error:', error);
            }
        }
    );

    // 處理保存
    const handleSave = () => {
        alterPortalStyleApi.mutate({
            type: type,
            name: name,
            style: selectedStyle,
            styleSetting: styleConfig,
        });
    }

    // 處理使用預設值
    const handleDefault = () => {
        Modal.confirm({
            title: translate('Confirm Default Settings'),
            content: translate('Are you sure you want to use default settings? This will overwrite current configurations.'),
            onOk: () => {
                // 使用默認配置
                configFields.forEach(field => {
                    onConfigChange(field.key, field.defaultValue);
                });
                // 重置選擇的樣式
                onStyleChange(styleOptions[0].value);
                // 顯示成功消息
                messageApi.success(translate('Default settings applied'));
            },
            onCancel: () => {
                console.log('取消使用預設值');
            },
        });
    };

    // 渲染配置字段
    const renderConfigField = (field: IConfigField) => {
        switch (field.type) {
            case ConfigFieldType.Color:
                return (
                    <Input
                        type="color"
                        value={styleConfig[field.key] || field.defaultValue || '#000000'}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case ConfigFieldType.Select:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || field.options?.[0]?.value || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={field.options?.map((option) => ({
                            label: translate(option.label),
                            value: option.value,
                        }))}
                    />
                );
            case ConfigFieldType.Number:
                return (
                    <InputNumber
                        value={styleConfig[field.key] || field.defaultValue || 0}
                        onChange={(value) => onConfigChange(field.key, value?.toString() || '')}
                        min={field.min}
                        max={field.max}
                    />
                );
            case ConfigFieldType.Input:
                return (
                    <Input
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case ConfigFieldType.Upload:
                const fileList = styleConfig[field.key] ?
                    Array.isArray(JSON.parse(styleConfig[field.key]))
                        ? JSON.parse(styleConfig[field.key])
                        : [JSON.parse(styleConfig[field.key])]
                    : [];

                return (
                    <Upload
                        fileList={fileList.map((url: string) => ({
                            uid: url,
                            name: url,
                            status: 'done',
                            url: url,
                        }))}
                        accept={field.acceptTypes ? field.acceptTypes.join(',') : 'image/*'}
                        showUploadList={true}
                        maxCount={field.maxUploadCount || 5}
                        multiple={field.maxUploadCount ? field.maxUploadCount > 1 : true}
                        onRemove={(file) => {
                            const newFileList = fileList.filter(item => item !== file.url);
                            onConfigChange(field.key, JSON.stringify(newFileList));
                        }}
                        beforeUpload={(file, fileList) => {
                            if (forMutipleUploadFileList === fileList) {
                                return false;
                            }
                            forMutipleUploadFileList = fileList;
                            uploadImagesMutation.mutate(fileList.map((file) => ({ file, fieldKey: field.key })));
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />} loading={uploadImagesMutation.isLoading}>
                            {fileList.length > 0 ? translate('Add More') : translate('Upload')}
                        </Button>
                    </Upload>
                );
            default:
                return null;
        }
    };

    // 渲染組件
    return (
        <>
            <Button
                style={{
                    margin: '20px',
                }}
                type="default"
                onClick={() => setDrawerVisible(true)}>
                {translate('Configure')}
            </Button>
            <Drawer
                title={translate('Style Configuration')}
                placement={deviceType === DeviceType.Mobile ? 'left' : 'right'}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                width={deviceType === DeviceType.Mobile ? '100%' : '30%'}
            >
                <Form layout="vertical">
                    <Form.Item key="style-selector" label={translate('Config.Style')}>
                        <Select value={selectedStyle} onChange={onStyleChange} options={styleOptions} />
                    </Form.Item>
                    {configFields.map((field) => (
                        <Form.Item
                            key={`config-field-${field.label}`}
                            label={
                                <Row align="middle" gutter={8}>
                                    {field.description && (
                                        <Col flex="none">
                                            <Tooltip title={translate(field.description)}>
                                                <InfoCircleOutlined style={{ color: 'blue' }} />
                                            </Tooltip>
                                        </Col>
                                    )}
                                    <Col flex="auto">
                                        {translate(field.label)}
                                    </Col>
                                </Row>
                            } >
                            {renderConfigField(field)}
                        </Form.Item>
                    ))}
                </Form>
                <Flex justify="space-between">
                    <Button
                        shape='round'
                        type='default'
                        onClick={handleDefault}>
                        {translate('Use Default')}
                    </Button>
                    <Button
                        shape='round'
                        type="primary"
                        loading={alterPortalStyleApi.isLoading}
                        onClick={handleSave}>
                        {translate('Save')}
                    </Button>
                </Flex>
            </Drawer>
        </>
    );
};

export default StyleConfigSelector;