import { DeviceType } from "@/Templates/enums/templateEnums";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Tabs, TabsProps } from "antd";
import React, { useContext, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import LogisticList from "./Components/Logistic/LogisticList";
import PaymentList from "./Components/Payment/PaymentList";
import PlatformServiceList from "./Components/PlatformService/PlatformServiceList";
import { ThirdPartyPageContext, ThirdPartyPageContextProvider } from "./Contexts/ThirdPartyPageContext";

type TabKey = 'paymentSettingList' | 'logisticSettingList' | 'platformServiceList' | 'invoiceSettingList';

const useThirdPartySettings = () => {
    const { deviceType } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(ThirdPartyPageContext);
    const [queryParams, setQueryParams] = useSearchParams();
    const [activeTabKey, setActiveTabKey] = React.useState<TabKey>('paymentSettingList');
    const [firstTime, setFirstTime] = React.useState(true);
    const paymentListRef = React.useRef<React.ElementRef<typeof PaymentList>>(null);
    const logisticListRef = React.useRef<React.ElementRef<typeof LogisticList>>(null);
    const platformServiceListRef = React.useRef<React.ElementRef<typeof PlatformServiceList>>(null);

    React.useEffect(() => {
        if (firstTime) {
            setFirstTime(false);
            return;
        }
        //cancel the previous request
        abortController.current.abort();
        abortController.current = new AbortController();
        switch (activeTabKey) {
            case 'paymentSettingList':
                paymentListRef.current?.onRefresh();
                break;
            case 'logisticSettingList':
                logisticListRef.current?.onRefresh();
                break;
            case 'platformServiceList':
                platformServiceListRef.current?.onRefresh();
                break;
        }
        setQueryParams({ tab: activeTabKey });
    }, [activeTabKey]);

    const items: TabsProps['items'] = [
        {
            key: 'paymentSettingList',
            label: `${translate('Payment')} ${translate('List')}`,
            children: <PaymentList ref={paymentListRef} />,
        },
        {
            key: 'logisticSettingList',
            label: `${translate('Logistic')} ${translate('List')}`,
            children: <LogisticList ref={logisticListRef} />,
        },
        {
            key: 'platformServiceList',
            label: `${translate('Platform')} ${translate('List')}`,
            children: <PlatformServiceList ref={platformServiceListRef} />,
        },
    ];

    useEffect(() => {
        const tab = queryParams.get('tab');
        if (tab) {
            setActiveTabKey(tab as TabKey);
        }
        return () => {
            abortController.current.abort();
        };
    }, []);

    return {
        deviceType,
        activeTabKey,
        setActiveTabKey,
        items,
        abortController,
    };
};

const ThirdPartyPageContent: React.FC = () => {
    const { deviceType, activeTabKey, setActiveTabKey, items } = useThirdPartySettings();

    return (
        <div>
            <Tabs
                activeKey={activeTabKey}
                items={items}
                tabPosition={deviceType === DeviceType.Mobile ? 'top' : 'left'}
                onChange={(key: string) => setActiveTabKey(key as TabKey)}
            />
        </div>
    );
};

const ThirdPartyPage: React.FC = () => {
    return (
        <ThirdPartyPageContextProvider>
            <ThirdPartyPageContent />
        </ThirdPartyPageContextProvider>
    );
};

export default ThirdPartyPage;