import { IQueryItemByMerchantRequest } from "@/interfaces/Requests/Requests";
import { INavBarOption } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { NavBarClickType } from "@/Templates/enums/templateEnums";
import { ICategory, IItem, IItemTag, IItemViewModel, IOperationResultT, IPaginationResponse } from "@/Templates/interfaces/templatesInterfaces";
import { CloseCircleOutlined, DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Flex, Form, Input, Select } from "antd";
import JSONBig from 'json-bigint';
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { PortalSettingPageContext } from "../Contexts/PortalSettingPageContext";
import '../PortalSettingPage.css';


export interface IEditNavBarOptionsProps {
    title?: string;
    layer: number;
    layerCount?: number;
    merchantId: BigInt;
    selectedOption: INavBarOption;
    style?: React.CSSProperties;
    merchantItems: IOperationResultT<IPaginationResponse<IItemViewModel[]>> | undefined;
    getMerchantItems: (request: IQueryItemByMerchantRequest) => void;
}

const EditNavBarOptions: React.FC<IEditNavBarOptionsProps> = ({
    title,
    layer,
    layerCount,
    style,
    merchantId,
    selectedOption,
    merchantItems,
    getMerchantItems
}) => {
    type ItemSearchSubType = 'Category' | 'Item' | 'Tag';
    type PageSubType = 'Home';
    const { generateHelper } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const {
        navBarSetting,
        setNavBarSetting,
        navBarOptions,
        setNavBarOptions,
        searchModel
    } = useContext(PortalSettingPageContext);
    const [itemSearchSubType, setItemSearchSubType] = useState<ItemSearchSubType>('Category');
    const [pageSubType, setPageSubType] = useState<PageSubType>('Home');
    const [form] = Form.useForm(undefined);
    const [layerStyle, setLayerStyle] = useState<React.CSSProperties>({
        ...style,
        borderColor: 'black'
    });

    const getQUeryItemByMerchantRequest = (option: INavBarOption) => {
        try {
            var response: string[] = [];
            var queryRequest = JSONBig.parse(option.extraInfo) as IQueryItemByMerchantRequest;
            queryRequest.extraItemIds?.forEach((id: BigInt) => {
                searchModel?.items.filter((item) => {
                    return item.id.toString() === id.toString();
                })
                    .map((item) => {
                        response.push(JSONBig.stringify(item));
                    })
            });
            return response;
        }
        catch {
            return {} as IQueryItemByMerchantRequest;
        }
    }

    const getGroupOptionDefaultValue = (option: INavBarOption): string[] => {
        try {
            var response: string[] = [];
            var ids = JSONBig.parse(option.extraInfo);
            ids.forEach((id: BigInt) => {
                navBarOptions.filter((o) => {
                    return o.id.toString() === id.toString()
                })
                    .map((o) => {
                        response.push(JSONBig.stringify(o));
                    })
            });
            return response;
        }
        catch {
            return [];
        }
    }

    const getCategroiesDefaultValue = (option: INavBarOption): string[] => {
        try {
            var response: string[] = [];
            var queryRequest = JSONBig.parse(option.extraInfo) as IQueryItemByMerchantRequest;
            queryRequest.extraItemTagIds?.forEach((id: BigInt) => {
                searchModel?.categories.filter((category) => {
                    return category.id.toString() === id.toString();
                })
                    .map((category) => {
                        response.push(JSONBig.stringify(category));
                    })
            });
            return response;
        }
        catch {
            return [];
        }
    }

    const getItemTagsDefaultValue = (option: INavBarOption): string[] => {
        try {
            var response: string[] = [];
            var queryRequest = JSONBig.parse(option.extraInfo) as IQueryItemByMerchantRequest;
            queryRequest.extraItemTagIds?.forEach((id: BigInt) => {
                searchModel?.itemTags.filter((tag) => {
                    return tag.id.toString() === id.toString();
                })
                    .map((tag) => {
                        response.push(JSONBig.stringify(tag));
                    })
            });
            return response;
        }
        catch {
            return [];
        }
    }

    const getItemSearchSubType = (option: INavBarOption) => {
        var request = option.extraInfo ? JSONBig.parse(option.extraInfo) as IQueryItemByMerchantRequest : undefined;
        return request?.extraItemIds ? 'Item' : request?.extraItemTagIds ? 'Tag' : 'Category';
    }

    const updateOption = (selectedOption: INavBarOption) => {
        setNavBarOptions([...navBarOptions.filter((option) => { return option.id !== selectedOption.id }), selectedOption].sort((a, b) => a.id.toString().localeCompare(b.id.toString())));
    }

    const AddSubOption = (selectedOption: INavBarOption) => {
        var newOption: INavBarOption = {
            id: generateHelper.getSnowflakeIdBigInt(),
            merchantId: merchantId!,
            name: "New",
            type: NavBarClickType.RedirectPage,
            extraInfo: "/",
            isDeleted: false,
            orderIndex: selectedOption.childrenIds.length + 1,
            childrenIds: [],
        }
        selectedOption.childrenIds.push(newOption.id);
        setNavBarOptions([...navBarOptions, newOption])
    }

    const handleClose = (selectedOption: INavBarOption) => {
        var options = [...navBarOptions.filter((option) => { return option.id !== selectedOption.id })];
        if (options.length == 0) {
            setNavBarSetting({ ...navBarSetting!, navBarOptionIds: [] });
        }
        else {
            setNavBarSetting({ ...navBarSetting!, navBarOptionIds: navBarSetting?.navBarOptionIds.filter((id) => { return id !== selectedOption.id }) ?? [] });
        }
        setNavBarOptions(options);
    }

    const rootOptions = (ids?: BigInt[]): ReactNode => {
        if (ids === undefined || ids.length === 0)
            return null;
        var options = navBarOptions.filter(r => ids.some(id => id.toString() === r.id.toString()));
        options.sort((a, b) => a.orderIndex - b.orderIndex);
        return options.map((option) => {
            return (
                <EditNavBarOptions
                    title={`${title} -> [${translate('Layer')}${layer + 1}] ${option.name}`}
                    layer={layer + 1}
                    layerCount={options.length}
                    merchantId={merchantId}
                    style={{ marginBottom: 10 }}
                    key={option.id.toString()}
                    merchantItems={merchantItems}
                    getMerchantItems={getMerchantItems}
                    selectedOption={option}
                >
                </EditNavBarOptions>
            )
        })
    }

    const handleOrderChange = (direction: 'up' | 'down') => {
        var _orderIndex = selectedOption.orderIndex;
        if (!layerCount) {
            layerCount = 1;
        }
        if (direction === 'up') {
            _orderIndex = _orderIndex - 1 < 1 ? 1 : _orderIndex - 1;
        }
        else {
            _orderIndex = _orderIndex + 1 > layerCount ? layerCount : _orderIndex + 1;
        }
        updateOption({
            ...selectedOption,
            orderIndex: _orderIndex
        });
    }

    const collapseContent = (option: INavBarOption): CollapseProps['items'] => [{
        key: `collapse_${option.id.toString()}`,
        label: `[${translate('Layer')}${layer}] ${option.name}`,
        children: <>
            <Form form={form}>
                <Form.Item name={'name'} label={translate('DisplayName')}>
                    <Input
                        onChange={(value) => {
                            updateOption({ ...selectedOption, name: value.target.value });
                        }}
                    >
                    </Input>
                </Form.Item>
                <Form.Item name={'type'} label={translate('Click type')}>
                    <Select
                        onChange={(value) => {
                            var extraInfo = '[]';
                            switch (value) {
                                case NavBarClickType.ItemSearch:
                                    extraInfo = JSONBig.stringify({ merchantId: merchantId, extraItemTagIds: [] });
                                    break;
                                case NavBarClickType.RedirectPage:
                                    extraInfo = '/';
                                    break;
                            }
                            updateOption({ ...selectedOption, type: value, extraInfo: extraInfo })
                        }}
                    >
                        {
                            Object.keys(NavBarClickType).map((type) => {
                                return (
                                    <Select.Option
                                        key={uuidv4()}
                                        value={type.toString()}
                                    >
                                        {translate(type.toString())}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                {
                    selectedOption.type === NavBarClickType.ItemSearch &&
                    <>
                        {
                            <Form.Item name={'itemSearchSubType'} label={translate('SubType')}>
                                <Select
                                    onChange={(value: ItemSearchSubType) => {
                                        setItemSearchSubType(value);
                                    }}>
                                    <Select.Option
                                        key={'Categor'}
                                        value={'Category'}
                                    >
                                        {translate('Category')}
                                    </Select.Option>
                                    <Select.Option
                                        key={'Item'}
                                        value={'Item'}
                                    >
                                        {translate('Item')}
                                    </Select.Option>
                                    <Select.Option
                                        key={'Tag'}
                                        value={'Tag'}
                                    >
                                        {translate('Tag')}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        }
                        {
                            itemSearchSubType === 'Category' &&
                            <Form.Item name={'categroies'} >
                                <Select
                                    onChange={(categoryJsons: string[]) => {
                                        var categories: ICategory[] = [];
                                        categoryJsons.forEach((json) => {
                                            var category = JSONBig.parse(json) as ICategory;
                                            categories.push(category);
                                        });
                                        var request: IQueryItemByMerchantRequest = {
                                            merchantId: merchantId,
                                            extraItemTagIds: categories.map((category) => { return category.id })
                                        }
                                        selectedOption.extraInfo = JSONBig.stringify(request);
                                    }}
                                >
                                    {
                                        searchModel?.categories.map((category) => {
                                            return (
                                                <Select.Option
                                                    key={uuidv4()}
                                                    value={category.toString()}
                                                >
                                                    {category.toString()}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                        {
                            itemSearchSubType === 'Tag' &&
                            <Form.Item name={'tags'}>
                                <Select
                                    mode="multiple"
                                    onChange={(tagsJsons: string[]) => {
                                        var tags: IItemTag[] = [];
                                        tagsJsons.forEach((json) => {
                                            var tag = JSONBig.parse(json) as IItemTag;
                                            tags.push(tag);
                                        });
                                        var request: IQueryItemByMerchantRequest = {
                                            merchantId: merchantId,
                                            extraItemTagIds: tags.map((tag) => { return tag.id })
                                        }
                                        selectedOption.extraInfo = JSONBig.stringify(request);
                                    }}
                                >
                                    {
                                        searchModel?.itemTags.map((itemTag) => {
                                            return (
                                                <Select.Option
                                                    key={itemTag.id.toString()}
                                                    value={JSONBig.stringify(itemTag)}
                                                >
                                                    {itemTag.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                        {
                            itemSearchSubType === 'Item' &&
                            <Form.Item name={'items'}>
                                <Select
                                    mode="multiple"
                                    onChange={(itemsJson: string[]) => {
                                        var items: IItem[] = [];
                                        itemsJson.forEach((json) => {
                                            var item = JSONBig.parse(json) as IItem;
                                            items.push(item);
                                        });
                                        var request: IQueryItemByMerchantRequest = {
                                            merchantId: merchantId,
                                            extraItemIds: items.map((item) => { return item.id })
                                        }
                                        selectedOption.extraInfo = JSONBig.stringify(request);
                                    }}>
                                    {
                                        searchModel?.items.map((item) => {
                                            return (
                                                <Select.Option
                                                    key={uuidv4()}
                                                    value={JSONBig.stringify(item)}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </>
                }
                {
                    selectedOption.type === NavBarClickType.RedirectPage &&
                    <>
                        {
                            <Form.Item name={'pages'} label={translate('Redirect pages')}>
                                <Select
                                    onChange={(value: PageSubType) => {
                                        selectedOption.extraInfo = '/';
                                    }}>
                                    <Select.Option
                                        key={'Home'}
                                        value={'Home'}
                                    >
                                        {translate('Home')}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        }
                    </>
                }
                {
                    selectedOption.type === NavBarClickType.RedirectItemDetailPage &&
                    <>
                        <Form.Item name={'redirectItemId'} label={translate('Item')}>
                            <Select
                                showSearch
                                placeholder={translate('Select an item')}
                                optionFilterProp="children"
                                value={selectedOption.extraInfo ? JSONBig.parse(selectedOption.extraInfo)?.extraItemIds?.[0]?.toString() : undefined}
                                onChange={(value: string) => {
                                    var request: IQueryItemByMerchantRequest = {
                                        merchantId: merchantId,
                                        extraItemIds: [BigInt(value)]
                                    }
                                    updateOption({ ...selectedOption, extraInfo: JSONBig.stringify(request) });
                                }}
                                onFocus={() => {
                                    getMerchantItems({ merchantId: merchantId });
                                }}
                                filterOption={(input, option) =>
                                    (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                            >
                                {merchantItems?.result?.data.map((item) => (
                                    <Select.Option key={item.id.toString()} value={item.id.toString()} label={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                }
            </Form>
            {rootOptions(selectedOption.childrenIds)}
            {
                layer <= 2 &&
                <Flex
                    justify="center"
                    style={{ margin: '10px' }}
                >
                    <Button
                        type="dashed"
                        block icon={<PlusOutlined />}
                        onClick={() => {
                            AddSubOption(selectedOption);
                        }}>
                        {`${translate('Add')} ${translate('Layer')} ${layer + 1} ${translate('Option')}`}
                    </Button>
                </Flex>
            }
        </>,
        extra: (
            <>
                <UpOutlined onClick={(event) => {
                    event.stopPropagation();
                    handleOrderChange('up');
                }} />
                <DownOutlined onClick={(event) => {
                    event.stopPropagation();
                    handleOrderChange('down');
                }} />
                <CloseCircleOutlined
                    onClick={() => handleClose(selectedOption)}
                    style={{ fontSize: 16, color: 'red' }}
                />
            </>
        )
    }];

    useEffect(() => {
        form.setFields([
            {
                name: ['name'],
                value: selectedOption.name
            },
            {
                name: ['type'],
                value: selectedOption.type
            }
        ])
        switch (selectedOption.type) {
            case NavBarClickType.ItemSearch:
                setItemSearchSubType(getItemSearchSubType(selectedOption));
                form.setFieldValue('itemSearchSubType', getItemSearchSubType(selectedOption));
                switch (getItemSearchSubType(selectedOption)) {
                    case 'Category':
                        form.setFieldValue('categroies', getCategroiesDefaultValue(selectedOption));
                        break;
                    case 'Item':
                        form.setFieldValue('items', getQUeryItemByMerchantRequest(selectedOption));
                        break;
                    case 'Tag':
                        form.setFieldValue('tags', getItemTagsDefaultValue(selectedOption));
                        break;
                }
                break;
            case NavBarClickType.RedirectPage:
                form.setFieldValue('pages', pageSubType);
                break;
            case NavBarClickType.RedirectItemDetailPage:
                const extraInfo = selectedOption.extraInfo ? JSONBig.parse(selectedOption.extraInfo) : undefined;
                const redirectItemId = extraInfo?.extraItemIds?.[0]?.toString();
                form.setFieldValue('redirectItemId', redirectItemId);
                break;
        }
        rootOptions(selectedOption.childrenIds);
    }, [navBarOptions]);

    return (
        selectedOption &&
        <Collapse
            items={collapseContent(selectedOption)}
            style={layerStyle}
        >
        </Collapse >
    )
}

export default EditNavBarOptions;