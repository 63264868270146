import { CurrencyCode } from '@/enums/CurrecnyCode';
import { useGetItemsByMerchantApi } from '@/lib/api/items';
import { useGetQueryOptionsByMerchantIdApi } from '@/lib/api/portals';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import {
    DeviceType,
    MarqueeType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    MerchantPortalStyleShopPages,
    NavBarClickType,
} from '@/Templates/enums/templateEnums';
import { ObjectComparisonHelper } from '@/Templates/helpers/ObjectComparisonHelper';
import {
    IItemSpec,
    IItemViewModel,
    INavBarOptionDto,
    IPaginationResponse,
    IPortalQueryItemByMerchantRequest,
    ISearchQueryState,
    MerchantMarqueeSettingViewModel,
} from '@/Templates/interfaces/templatesInterfaces';
import {
    ItemCardTemplateProps,
    ItemDetailPageTemplateProps,
    NavBarTemplateProps,
    PagesTemplateProps,
    TemplateProps,
} from '@/Templates/TemplateProps';
import {
    LaptopOutlined,
    MobileOutlined,
    TabletOutlined,
} from '@ant-design/icons';
import { Button, Col, Empty, Flex, Row, Select, Tabs } from 'antd';
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import StyleConfigSelector from './StyleConfigSelector';

export interface ShopPreviewProps extends TemplateProps {
    selectedComponentStyle: number;
    selectedPageStyle: number;
    onComponentConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
    onPageConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
}

export interface IShopStyleSelectorRef {
    onRefresh: () => void;
}

// 测试数据
const testItem: IItemViewModel = {
    id: BigInt(1),
    name: '测试商品',
    title: '高品质测试商品',
    briefly: '这是一个用于测试的商品项目',
    description:
        '这是一个详细的商品描述，用于测试商品展示功能。它包含了商品的各种特性和优点。',
    createdDate: new Date().toISOString(),
    alterDate: new Date().toISOString(),
    isDelete: false,
    itemConverImagePaths: [
        'https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg',
        'https://storage.googleapis.com/microshop-images-bucket/system/BackgroundImage1.jpg',
        'https://storage.googleapis.com/microshop-images-bucket/system/BackgroundImage2.jpg',
    ],
    itemTags: [
        {
            id: BigInt(1),
            merchantId: BigInt(1),
            name: '测试标签',
            isDeleted: false,
        },
    ],
    minPrice: 99.99,
    maxPrice: 199.99,
    isOnShelf: true,
};

const testMenuItems: INavBarOptionDto[] = [
    {
        id: BigInt(1),
        merchantId: BigInt(1),
        name: '首页',
        type: NavBarClickType.None,
        extraInfo: '/',
        isDeleted: false,
        orderIndex: 1,
        childrenIds: [],
    },
    {
        id: BigInt(2),
        merchantId: BigInt(1),
        name: '商品分类',
        type: NavBarClickType.None,
        extraInfo: '',
        isDeleted: false,
        orderIndex: 2,
        childrenIds: [BigInt(5), BigInt(6)],
        children: [
            {
                id: BigInt(5),
                merchantId: BigInt(1),
                name: '服装',
                type: NavBarClickType.ItemSearch,
                extraInfo: 'clothing',
                isDeleted: false,
                orderIndex: 1,
                childrenIds: [],
            },
            {
                id: BigInt(6),
                merchantId: BigInt(1),
                name: '配饰',
                type: NavBarClickType.ItemSearch,
                extraInfo: 'accessories',
                isDeleted: false,
                orderIndex: 2,
                childrenIds: [],
            },
        ],
    },
    {
        id: BigInt(3),
        merchantId: BigInt(1),
        name: '关于我们',
        type: NavBarClickType.None,
        extraInfo: '/about',
        isDeleted: false,
        orderIndex: 3,
        childrenIds: [],
    },
    {
        id: BigInt(4),
        merchantId: BigInt(1),
        name: '联系我们',
        type: NavBarClickType.None,
        extraInfo: '/contact',
        isDeleted: false,
        orderIndex: 4,
        childrenIds: [],
    },
];

const testMarqueeViewModel: MerchantMarqueeSettingViewModel = {
    id: BigInt(1),
    merchantId: BigInt(1),
    imagePaths: [
        'https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg',
        'https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg',
        'https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg',
    ],
    imageIds: [],
    externalLinks: [],
    createdDate: new Date().toISOString(),
    type: MarqueeType.MerchantMain,
};

const testItemSpecs: IItemSpec[] = [
    {
        id: BigInt(1),
        name: '测试规格',
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 100,
        fixedPrice: 100,
        isManageStockAmount: false,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
    },
    {
        id: BigInt(2),
        name: '测试规格-2',
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        isManageStockAmount: true,
        stockAmount: 100,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
    },
];

interface StyleState {
    style: number;
    configFields: Array<any>;
    styleConfig: Record<string, string>;
}

const PreviewContainer = styled.div<{ containerWidth: number, previewwidth: string; previewDeviceType: DeviceType, windowDeviceType: DeviceType }>`
  border: 1px solid #ccc;
  overflow-y: scroll;
  overflow-x: hidden; 
  display: flex;
  align-items: flex-start; // 改為靠上對齊
  justify-content: center;
  width: ${props => `${props.previewwidth}`};
  max-width: ${props => props.windowDeviceType === DeviceType.Desktop ? '100%' : '90%'};
  margin: 0 auto;
  position: relative;
  height: 75vh; // 設置固定高度
  max-height: 80vh;

  & > div {
    width: ${props => props.previewwidth};
    height: ${props => props.previewDeviceType === DeviceType.Mobile ? '100%' : 'auto'};
    transform-origin: top center; // 改為從頂部中心縮放
    transition: transform 0.3s ease;
    position: absolute;
    top: 0; // 靠上對齊
    left: 50%;
  }

  ${props => {
        const previewWidth = props.previewwidth;
        const previewWidthNumber = parseInt(previewWidth.replace('px', ''));
        // 計算縮放比例
        const scale = Math.min(props.containerWidth / previewWidthNumber, 1);
        return `
      & > div {
        transform: translateX(-50%) scale(${scale});
      }
    `;
    }}

  /* 定義自定義容器查詢 */
  container-type: inline-size;
  container-name: preview-container;

  /* 添加內部容器以確保內容可以水平滾動 */
  & > div {
    min-width: 100%;
    width: ${props => props.previewwidth};
  }

  /* 自定義滾動條樣式 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ShopPreview = forwardRef<IShopStyleSelectorRef, ShopPreviewProps>(
    ({
        merchantId,
        messageApi
    }, ref) => {
        var queryRequest: IPortalQueryItemByMerchantRequest = {
            merchantId,
            categoryIds: []
        };
        const [testPaginationItems, setTestPaginationItems] = useState<IPaginationResponse<IItemViewModel[]>>({
            data: [testItem],
            totalCount: 1,
            totalPage: 1
        });
        const [testSearchQueryState, setTestSearchQueryState] = useState<ISearchQueryState>({
            options: {
                isSuccess: true,
                result: {
                    itemTags: [],
                    categories: [],
                }
            },
            form: undefined,
            setRequest: function (request: any): void {

            },
            search: function (request: any): void {

            },
            loading: false,
            onFormValuesChange: (changedValues: any, allValues: any) => {
                queryRequest = {
                    merchantId: merchantId,
                    fuzzingName: allValues.fuzzingName,
                    categoryIds: allValues.categoryIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                    extraItemTagIds: allValues.itemTagIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                    extraItemIds: allValues.extraItemIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                };
            },
            onSearch: function (): void {
                useGetItemsByMerchantApi(queryRequest).then((res) => {
                    if (res.result) {
                        if (testPaginationItems.data.length !== res.result.data.length) {
                            setTestPaginationItems(res.result || []);
                        }
                    }
                });
            },
            isLoading: false
        });
        const {
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            deviceType: windowDeviceType,
        } = useContext(GlobalContext);
        const { translate } = useContext(TranslationContext);
        const [previewDeviceType, setPreviewDeviceType] = useState<DeviceType>(
            DeviceType.Desktop
        );
        const previewContainerRef = useRef<HTMLDivElement>(null);
        const [containerWidth, setContainerWidth] = useState<number>(0);
        const [ComponentTemplateComponent, setComponentTemplateComponent] =
            useState<React.ComponentType<TemplateProps> | null>(null);
        const [PageTemplateComponent, setPageTemplateComponent] =
            useState<React.ComponentType<PagesTemplateProps> | null>(null);
        const [mockComponentProps, setMockComponentProps] = useState<any>(null);
        const [mockPageProps, setMockPageProps] = useState<any>(null);
        const [selectedPageType, setSelectedPageType] =
            useState<MerchantPortalStyleShopPages>(
                MerchantPortalStyleShopPages.LandingPage
            );
        const [selectedComponentType, setSelectedComponentType] =
            useState<MerchantPortalStyleShopComponents>(
                MerchantPortalStyleShopComponents.Marquee
            );
        const [refreshComponentCount, setRefreshComponentCount] = useState(0);
        const [refreshPageCount, setRefreshPageCount] = useState(0);
        const [activeTab, setActiveTab] = useState<MerchantPortalStyleSettingType>(
            MerchantPortalStyleSettingType.Components
        );
        const [styleState, setStyleState] = useState<{
            component: StyleState;
            page: StyleState;
        }>({
            component: {
                style: 0,
                configFields: [],
                styleConfig: {},
            },
            page: {
                style: 0,
                configFields: [],
                styleConfig: {},
            },
        });
        const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(
            testItemSpecs[0]
        );
        const [purchaseQuantity, setPurchaseQuantity] = useState<number>(0);
        const merchantAlreadyFilledConfigs = useMemo(
            () =>
                merchantPortalStyleSettings.find(
                    (x) =>
                        x.type === MerchantPortalStyleSettingType.Components &&
                        x.name === selectedComponentType &&
                        x.style === styleState.component.style
                ),
            [
                merchantPortalStyleSettings,
                selectedComponentType,
                styleState.component.style,
            ]
        );
        const loadComponentTemplate = useCallback(async () => {
            try {
                const componentModule = await import(
                    `@/Templates/Shop/Components/${selectedComponentType}/Template_${styleState.component.style}.tsx`
                );
                setComponentTemplateComponent(() => componentModule.default);

                let newFields = [];
                if (componentModule.getConfigFields) {
                    newFields = componentModule.getConfigFields();
                }

                const currentFields = styleState.component.configFields;
                const fieldsChanged = !areConfigFieldsEqual(newFields, currentFields);

                const merchantAlreadyFilledConfigs = merchantPortalStyleSettings.find(
                    (x) =>
                        x.type === MerchantPortalStyleSettingType.Components &&
                        x.name === selectedComponentType &&
                        x.style === styleState.component.style
                );

                if (fieldsChanged) {
                    setStyleState((prevState) => ({
                        ...prevState,
                        component: {
                            ...prevState.component,
                            configFields: newFields,
                            styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                        },
                    }));
                    setRefreshComponentCount((prev) => prev + 1);
                }
            } catch (error) {
                console.error('加载组件模板失败:', error);
                setComponentTemplateComponent(null);
                setStyleState((prevState) => ({
                    ...prevState,
                    component: {
                        ...prevState.component,
                        configFields: [],
                        styleConfig: {},
                    },
                }));
            }
        }, [
            selectedComponentType,
            styleState.component.style,
            merchantPortalStyleSettings,
        ]);

        const loadPageTemplate = useCallback(async () => {
            try {
                const pageModule = await import(
                    `@/Templates/Shop/Pages/${selectedPageType}/Template_${styleState.page.style}.tsx`
                );
                setPageTemplateComponent(() => pageModule.default);

                let newFields = [];
                if (pageModule.getConfigFields) {
                    newFields = pageModule.getConfigFields();
                }

                const currentFields = styleState.page.configFields;
                const fieldsChanged = !areConfigFieldsEqual(newFields, currentFields);

                const merchantAlreadyFilledConfigs = merchantPortalStyleSettings.find(
                    (x) =>
                        x.type === MerchantPortalStyleSettingType.Pages &&
                        x.name === selectedPageType &&
                        x.style === styleState.page.style
                );

                if (fieldsChanged) {
                    setStyleState((prevState) => ({
                        ...prevState,
                        page: {
                            ...prevState.page,
                            configFields: newFields,
                            styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                        },
                    }));
                    setRefreshPageCount((prev) => prev + 1);
                }
            } catch (error) {
                console.error('加载页面模板失败:', error);
                setPageTemplateComponent(null);
                setStyleState((prevState) => ({
                    ...prevState,
                    page: {
                        ...prevState.page,
                        configFields: [],
                        styleConfig: {},
                    },
                }));
            }
        }, [
            selectedPageType,
            styleState.page.style,
            merchantPortalStyleSettings,
        ]);

        const loadMockComponentProps = useCallback(() => {
            try {
                switch (selectedComponentType) {
                    case MerchantPortalStyleShopComponents.NavBar:
                        const navBarProps: NavBarTemplateProps = {
                            merchantId,
                            BASE_PATH: '/base-path',
                            menuItems: testMenuItems,
                            isHideNavBar: false,
                            merchantPortalOptionSetting: merchantPortalOptionSetting,
                            navBarHeight: 60,
                            deviceType: previewDeviceType,
                            shoppingCartItemCount: 0,
                            isLogin: false,
                            translate,
                            isPreviewMode: true,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            config: styleState.component.styleConfig,
                            messageApi,
                            previewContainer: previewContainerRef.current
                                ? previewContainerRef.current
                                : undefined,
                        };
                        setMockComponentProps({ ...navBarProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopComponents.ItemCard:
                        const itemCardProps: ItemCardTemplateProps = {
                            item: testItem,
                            isloading: false,
                            deviceType: previewDeviceType,
                            isPreviewMode: true,
                            merchantId,
                            BASE_PATH: '',
                            translate: translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                        };
                        setMockComponentProps({ ...itemCardProps, isCentered: true });
                        break;
                    case MerchantPortalStyleShopComponents.Marquee:
                        const MarqueeProps: TemplateProps = {
                            deviceType: previewDeviceType,
                            isPreviewMode: true,
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.component.styleConfig,
                        };
                        setMockComponentProps({ ...MarqueeProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopComponents.ScrollToTopBtn:
                        const scrollToTopBtnProps: TemplateProps = {
                            deviceType: previewDeviceType,
                            isPreviewMode: true,
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.component.styleConfig,
                        };
                        setMockComponentProps({ ...scrollToTopBtnProps, isCentered: false });
                        break;
                    default:
                        setMockComponentProps(null);
                }
            } catch (error) {
                console.error('加载组件属性失败:', error);
                setMockComponentProps(null);
            }
        }, [
            selectedComponentType,
            merchantId,
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            previewDeviceType,
            translate,
            styleState.component.styleConfig,
            messageApi,
            previewContainerRef,
        ]);

        const loadMockPageProps = useCallback(async () => {
            try {
                switch (selectedPageType) {
                    case MerchantPortalStyleShopPages.LandingPage:
                        const landingPageProps: PagesTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.page.styleConfig,
                            deviceType: previewDeviceType,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...landingPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.HomePage:
                        useGetItemsByMerchantApi(queryRequest).then((res) => {
                            if (res.result) {
                                if (testPaginationItems.data.length !== res.result.data.length) {
                                    setTestPaginationItems(res.result || []);
                                }
                            }
                        });
                        const homePageProps: PagesTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            items: testPaginationItems,
                            config: styleState.page.styleConfig,
                            deviceType: previewDeviceType,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...homePageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.ItemDetailPage:
                        const itemDetailPageProps: ItemDetailPageTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.page.styleConfig,
                            deviceType: previewDeviceType,
                            item: testItem,
                            itemSpecs: testItemSpecs,
                            selectedItemSpec: selectedItemSpec,
                            purchaseQuantity: purchaseQuantity,
                            isAddToShoppingCartLoading: false,
                            isAddToTempShoppingCartLoading: false,
                            handleSelectChange: function (value: string): void {
                                setSelectedItemSpec(
                                    testItemSpecs.find((x) => x.id.toString() === value) || null
                                );
                            },
                            setPurchaseQuantity: function (value: number): void {
                                setPurchaseQuantity(value);
                            },
                            handleSubmit: function (): void {
                                messageApi.info('handleSubmit');
                            },
                            shoppingCartItemCount: 0,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...itemDetailPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.SearchPage:
                        const queryOptions = await useGetQueryOptionsByMerchantIdApi(merchantId);
                        //check queryOptions is not same as testSearchQueryState.options
                        if (!ObjectComparisonHelper.areObjectsEqual(queryOptions, testSearchQueryState.options)) {
                            setTestSearchQueryState(prevState => ({
                                ...prevState,
                                options: queryOptions
                            }));
                        }
                        const searchPageProps: PagesTemplateProps = {
                            deviceType: previewDeviceType,
                            BASE_PATH: '/base-path',
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            items: testPaginationItems,
                            queryState: testSearchQueryState,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...searchPageProps, isCentered: false });
                        break;
                    default:
                        setMockPageProps(null);
                }
            } catch (error) {
                console.error('加载页面属性失败:', error);
                setMockPageProps(null);
            }
        }, [
            selectedPageType,
            merchantId,
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            previewDeviceType,
            translate,
            styleState.page.styleConfig,
            messageApi,
            selectedItemSpec,
            purchaseQuantity,
            testPaginationItems,
            testSearchQueryState
        ]);

        useEffect(() => {
            loadComponentTemplate();
        }, [
            activeTab,
            selectedComponentType,
            styleState.component.style,
            loadComponentTemplate,
        ]);

        useEffect(() => {
            loadMockComponentProps();
        }, [
            activeTab,
            selectedComponentType,
            styleState.component.style,
            previewDeviceType,
            styleState.component.styleConfig,
            refreshComponentCount,
            loadMockComponentProps,
        ]);

        useEffect(() => {
            loadPageTemplate();
        }, [activeTab, selectedPageType, styleState.page.style, loadPageTemplate]);

        useEffect(() => {
            loadMockPageProps();
        }, [
            activeTab,
            selectedPageType,
            styleState.page.style,
            previewDeviceType,
            styleState.page.styleConfig,
            refreshPageCount,
            loadMockPageProps,
            selectedItemSpec,
            purchaseQuantity,
        ]);

        useImperativeHandle(ref, () => ({
            onRefresh: () => {
                loadComponentTemplate();
                loadPageTemplate();
                loadMockComponentProps();
                loadMockPageProps();
            },
        }));

        useEffect(() => {
            const updateWidth = () => {
                if (previewContainerRef.current) {
                    const rect = previewContainerRef.current.getBoundingClientRect();
                    setContainerWidth(rect.width);
                }
            };

            updateWidth(); // 初始化寬度
            window.addEventListener('resize', updateWidth);

            // 創建一個 ResizeObserver 來監聽容器大小變化
            const resizeObserver = new ResizeObserver(updateWidth);
            if (previewContainerRef.current) {
                resizeObserver.observe(previewContainerRef.current);
            }

            return () => {
                window.removeEventListener('resize', updateWidth);
                resizeObserver.disconnect();
            };
        }, []);

        const handleStyleChange = (type: 'component' | 'page', value: number) => {
            setStyleState((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState[type],
                    style: value,
                },
            }));
        };

        const handleConfigChange = (
            type: 'component' | 'page',
            key: string,
            value: string
        ) => {
            setStyleState((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState[type],
                    styleConfig: {
                        ...prevState[type].styleConfig,
                        [key]: value,
                    },
                },
            }));
            if (type === 'component') {
                setRefreshComponentCount((prev) => prev + 1);
            } else {
                setRefreshPageCount((prev) => prev + 1);
            }
        };

        const areConfigFieldsEqual = (fields1: any[], fields2: any[]) => {
            if (fields1.length !== fields2.length) return false;
            return fields1.every(
                (field, index) =>
                    field.key === fields2[index].key &&
                    field.label === fields2[index].label &&
                    field.type === fields2[index].type &&
                    field.defaultValue === fields2[index].defaultValue
            );
        };

        // 预览设备类型切换
        const handlePreviewDeviceChange = (type: DeviceType) => {
            setPreviewDeviceType(type);
        };

        const getPreviewWidth = () => {
            switch (previewDeviceType) {
                case DeviceType.Mobile:
                    return '375px';
                case DeviceType.LapTop:
                    return '768px';
                case DeviceType.Desktop:
                default:
                    return '1024px';
            }
        };

        const items = [
            {
                key: MerchantPortalStyleSettingType.Components,
                label: translate('Components'),
                children: (
                    <div>
                        <Row style={{ marginBottom: 16 }}>
                            <Col span={windowDeviceType === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowDeviceType === DeviceType.Desktop ? 0 : 16 }}>
                                <Flex justify="center" align="center">
                                    <Select
                                        style={{ width: 200 }}
                                        value={selectedComponentType}
                                        onChange={(value) => setSelectedComponentType(value)}
                                    >
                                        {Object.values(MerchantPortalStyleShopComponents).map((component) => (
                                            <Select.Option key={component} value={component}>
                                                {translate(component)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Flex>
                            </Col>
                            <Col span={windowDeviceType === DeviceType.Desktop ? 12 : 24}>
                                <Flex justify="center" align="center">
                                    <Button
                                        icon={<MobileOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                                        type={previewDeviceType === DeviceType.Mobile ? 'primary' : 'default'}
                                    >
                                        {translate('Mobile')}
                                    </Button>
                                    <Button
                                        icon={<TabletOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                                        type={previewDeviceType === DeviceType.LapTop ? 'primary' : 'default'}
                                    >
                                        {translate('Laptop')}
                                    </Button>
                                    <Button
                                        icon={<LaptopOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                                        type={previewDeviceType === DeviceType.Desktop ? 'primary' : 'default'}
                                    >
                                        {translate('Desktop')}
                                    </Button>
                                </Flex>
                            </Col>
                        </Row>
                        {activeTab === 'Components' && (
                            <PreviewContainer
                                ref={previewContainerRef}
                                containerWidth={containerWidth}
                                previewwidth={getPreviewWidth()}
                                previewDeviceType={previewDeviceType}
                                windowDeviceType={windowDeviceType}
                            >
                                <div>
                                    {ComponentTemplateComponent ? (
                                        mockComponentProps ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: mockComponentProps.isCentered
                                                        ? 'center'
                                                        : 'flex-start',
                                                    alignItems: mockComponentProps.isCentered
                                                        ? 'center'
                                                        : 'flex-start',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <ComponentTemplateComponent {...mockComponentProps} />
                                            </div>
                                        ) : (
                                            <Empty description={translate('No props')} />
                                        )
                                    ) : (
                                        <Empty description={translate('No component template')} />
                                    )}
                                </div>
                            </PreviewContainer>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyleConfigSelector
                                type={MerchantPortalStyleSettingType.Components}
                                name={selectedComponentType}
                                selectedStyle={styleState.component.style}
                                styleConfig={styleState.component.styleConfig}
                                configFields={styleState.component.configFields}
                                onStyleChange={(value) => handleStyleChange('component', value)}
                                onConfigChange={(key, value) =>
                                    handleConfigChange('component', key, value)
                                }
                                styleOptions={[
                                    { value: 0, label: translate('Style 1') },
                                    { value: 1, label: translate('Style 2') },
                                ]}
                            />
                        </div>
                    </div>
                ),
            },
            {
                key: MerchantPortalStyleSettingType.Pages,
                label: translate('Pages'),
                children: (
                    <div>
                        <Row style={{ marginBottom: 16 }}>
                            <Col span={windowDeviceType === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowDeviceType === DeviceType.Desktop ? 0 : 16 }}>
                                <Flex justify="center" align="center">
                                    <Select
                                        style={{ width: 200 }}
                                        value={selectedPageType}
                                        onChange={(value: MerchantPortalStyleShopPages) =>
                                            setSelectedPageType(value)
                                        }
                                    >
                                        {Object.values(MerchantPortalStyleShopPages).map((page) => (
                                            <Select.Option key={page} value={page}>
                                                {translate(page)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Flex>
                            </Col>
                            <Col span={windowDeviceType === DeviceType.Desktop ? 12 : 24}>
                                <Flex justify="center" align="center">
                                    <Button
                                        icon={<MobileOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                                        type={previewDeviceType === DeviceType.Mobile ? 'primary' : 'default'}
                                    >
                                        {translate('Mobile')}
                                    </Button>
                                    <Button
                                        icon={<TabletOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                                        type={previewDeviceType === DeviceType.LapTop ? 'primary' : 'default'}
                                    >
                                        {translate('Laptop')}
                                    </Button>
                                    <Button
                                        icon={<LaptopOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                                        type={previewDeviceType === DeviceType.Desktop ? 'primary' : 'default'}
                                    >
                                        {translate('Desktop')}
                                    </Button>
                                </Flex>
                            </Col>
                        </Row>
                        {activeTab === 'Pages' && (
                            <PreviewContainer
                                ref={previewContainerRef}
                                containerWidth={containerWidth}
                                previewwidth={getPreviewWidth()}
                                previewDeviceType={previewDeviceType}
                                windowDeviceType={windowDeviceType}
                            >
                                <div>
                                    {PageTemplateComponent ? (
                                        mockPageProps ? (
                                            <PageTemplateComponent {...mockPageProps} />
                                        ) : (
                                            <Empty description={translate('No props')} />
                                        )
                                    ) : (
                                        <Empty description={translate('No page template')} />
                                    )}
                                </div>
                            </PreviewContainer>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyleConfigSelector
                                type={MerchantPortalStyleSettingType.Pages}
                                name={selectedPageType}
                                selectedStyle={styleState.page.style}
                                styleConfig={styleState.page.styleConfig}
                                configFields={styleState.page.configFields}
                                onStyleChange={(value) => handleStyleChange('page', value)}
                                onConfigChange={(key, value) => handleConfigChange('page', key, value)}
                                styleOptions={[
                                    { value: 0, label: translate('Style 1') },
                                    { value: 1, label: translate('Style 2') },
                                ]}
                            />
                        </div>
                    </div>
                ),
            },
        ];

        return (
            <Row>
                <Col span={24}>
                    <Tabs
                        items={items}
                        activeKey={activeTab}
                        onChange={(key) => setActiveTab(key as MerchantPortalStyleSettingType)}
                    />
                </Col>
            </Row>
        );
    }
);

export default ShopPreview;