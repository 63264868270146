import { MerchantRoleEnum } from "@/enums/Enums";
import { IAlterUserRolesRequest } from "@/interfaces/Requests/Permission";
import { IMerchantAssistant } from "@/interfaces/Responses/Responses";
import { useAlterUserRolesApi, useGetCanOperateRolesApi, useGetMyRolesApi, useGetOwnRolesApi } from "@/lib/api/permission";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { Button, Checkbox, Flex, Form, Modal, Space, Spin, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

const { Text } = Typography;

interface PermissionModalProps {
    isVisible: boolean;
    onCancel: () => void;
    selectedAssistant: IMerchantAssistant | null;
}

const PermissionModal: React.FC<PermissionModalProps> = ({ isVisible, onCancel, selectedAssistant }) => {
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const [form] = Form.useForm();
    const [selectedRoles, setSelectedRoles] = useState<MerchantRoleEnum[]>([]);

    const { data: myRoles, isLoading: isLoadingMyRoles } = useQuery<IOperationResultT<MerchantRoleEnum[]>, Error>(
        'myRoles',
        () => useGetMyRolesApi(undefined),
        {
            onError: () => {
                messageApi.error(translate('Failed to fetch your roles'));
            }
        }
    );

    const { data: ownRoles, isLoading: isLoadingOwnRoles } = useQuery<IOperationResultT<MerchantRoleEnum[]>, Error>(
        ['ownRoles', selectedAssistant?.userId],
        () => useGetOwnRolesApi(selectedAssistant?.userId || ''),
        {
            enabled: !!selectedAssistant?.userId && isVisible,
            onError: () => {
                messageApi.error(translate('Failed to fetch assistant\'s current roles'));
            }
        }
    );

    const { data: canOperateRoles, isLoading: isLoadingCanOperateRoles } = useQuery<IOperationResultT<MerchantRoleEnum[]>, Error>(
        ['canOperateRoles', selectedAssistant?.userId],
        () => useGetCanOperateRolesApi(selectedAssistant?.userId || ''),
        {
            enabled: !!selectedAssistant?.userId && isVisible,
            onError: () => {
                messageApi.error(translate('Failed to fetch operable roles'));
            }
        }
    );

    const alterUserRolesMutation = useMutation<IOperationResult, Error, IAlterUserRolesRequest>(
        useAlterUserRolesApi,
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Roles updated successfully'));
                    onCancel();
                } else {
                    messageApi.error(translate(response.message || 'Failed to update roles'));
                }
            },
            onError: (error) => {
                messageApi.error(translate('An error occurred while updating roles'));
            }
        }
    );

    useEffect(() => {
        if (isVisible && selectedAssistant && ownRoles?.result) {
            setSelectedRoles(ownRoles.result);
            form.setFieldsValue({ roles: ownRoles.result });
        }
    }, [isVisible, selectedAssistant, ownRoles, form]);

    const handleRoleChange = (checkedValues: MerchantRoleEnum[]) => {
        setSelectedRoles(checkedValues);
    };

    const handleSubmit = () => {
        if (selectedAssistant) {
            Modal.confirm({
                title: translate('Confirm Role Changes'),
                content: translate('Are you sure you want to update the roles for this assistant?'),
                onOk() {
                    alterUserRolesMutation.mutate({
                        merchantId: selectedAssistant.merchantId,
                        userId: selectedAssistant.userId,
                        roles: selectedRoles
                    });
                },
            });
        }
    };

    const isLoading = isLoadingMyRoles || isLoadingOwnRoles || isLoadingCanOperateRoles || alterUserRolesMutation.isLoading;
    const isDataReady = !isLoading && myRoles?.result && ownRoles?.result && canOperateRoles?.result;

    return (
        <Modal
            title={translate('Edit')}
            open={isVisible}
            onCancel={onCancel}
            footer={null}
            width={600}
        >
            {!isDataReady ? (
                <Flex justify="center" align="center" style={{ height: 200 }}>
                    <Spin size="large" />
                </Flex>
            ) : (
                <Flex vertical align="center" style={{ width: '100%' }}>
                    <Space direction="vertical" size="large" style={{ width: '100%', maxWidth: 400 }}>
                        <div>
                            <Text strong>{translate('My permissions')}:</Text>
                            <div>
                                {myRoles?.result && myRoles.result.length > 0 ? (
                                    myRoles.result.map((role) => (
                                        <Text key={role} style={{ marginRight: 8 }}>
                                            {translate(`MerchantRoleEnum.${MerchantRoleEnum[role]}`)}
                                        </Text>
                                    ))
                                ) : (
                                    <Text type="secondary">{translate('None')}</Text>
                                )}
                            </div>
                        </div>
                        <Form
                            form={form}
                            layout="vertical"
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                name="roles"
                                label={`${translate('Assistant')} ${translate('permissions')}`}
                            >
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    value={selectedRoles}
                                    onChange={handleRoleChange as any}
                                >
                                    <Flex vertical>
                                        {Object.entries(MerchantRoleEnum).map(([key, value]) => (
                                            <Checkbox
                                                key={key}
                                                value={value}
                                                disabled={!canOperateRoles?.result?.includes(value)}
                                            >
                                                {translate(`MerchantRoleEnum.${key}`)}
                                            </Checkbox>
                                        ))}
                                    </Flex>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item>
                                <Flex justify="center">
                                    <Button
                                        type="primary"
                                        onClick={handleSubmit}
                                        loading={alterUserRolesMutation.isLoading}
                                    >
                                        {translate('Save Changes')}
                                    </Button>
                                </Flex>
                            </Form.Item>
                        </Form>
                    </Space>
                </Flex>
            )}
        </Modal>
    );
};

export default PermissionModal;
