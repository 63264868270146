import { IItemViewModel, IOperationResult, IOperationResultT } from '@/Templates/interfaces/templatesInterfaces';
import {
    IAlterItemIsOnShelfRequest,
    IDeleteItemRequest,
    IQueryItemByMerchantRequest
} from '../../interfaces/Requests/Requests';
import {
    IItemSpecDto,
    IPaginationResponse
} from '../../interfaces/Responses/Responses';
import useAPI from '../customHooks/useAPI';

export const useGetItemByMerchantApi = (itemId: BigInt) => useAPI<IOperationResultT<IItemViewModel>>(`${process.env.BASE_API_URL}/api/Item/GetItemByMerchant?itemId=${itemId.toString()}`);

export const useGetItemsByMerchantApi = (queryRequest: IQueryItemByMerchantRequest, signal?: AbortSignal) => {
    return useAPI<IOperationResultT<IPaginationResponse<IItemViewModel[]>>>(`${process.env.BASE_API_URL}/api/Item/GetItemsByMerchant`, {
        method: 'GET',
        body: queryRequest,
        signal
    });
};

export const useGetItemSpecsApi = (merchantId: BigInt, itemId: BigInt) => useAPI<IOperationResultT<IItemSpecDto[]>>(`${process.env.BASE_API_URL}/api/Item/GetItemSpecs?merchantId=${merchantId}&itemId=${itemId.toString()}`);

export const useCreateItemApi = (params: FormData) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Item/CreateItem`, {
    method: 'POST',
    body: params,
    contentType: 'multipart/form-data'
});

export const useAlterItemApi = (params: FormData) => useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Item/AlterItem`, {
    method: 'POST',
    body: params,
    contentType: 'multipart/form-data'
});

export const useDeleteItemApi = (params: IDeleteItemRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Item/DeleteItem`, {
    method: 'DELETE',
    body: params
});

export const useAlterItemIsOnShelfApi = (request: IAlterItemIsOnShelfRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Item/AlterItemIsOnShelf`, {
    method: 'POST',
    body: request,
    signal
});